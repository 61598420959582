.App {
  color: #7A7A7A;
  font-family: Avenir;
}

p {
  font-size: 1.125rem;
}

section h1 {
  color: #075F9D;
  font-size: 2rem;
  margin-bottom: 5%!important;
}

section {
  padding: 10vw;
}

.gray-background {
  background-color: #f6f8f8;
}

/*
 * Color scheme
 */

 .blue-color {
  color:#075F9D;
}

.highlight {
  color: #FFAC50;
  font-weight: bold;
}


/*
 * Buttons
 */

 .btn-outline-primary {
  border-color: #075F9D;
  color: #075F9D;
}

.btn-outline-primary:hover {
  border-color: #075F9D;
  background-color: #075F9D;
  color: white;
}

/*
 * Navbar
 */

 .nav {
  top: 0px;
  width: 100%;
  height: 100px;
  z-index: 1000;
  display: flex;
  justify-content: right;
}

.nav .nav-content {
  margin: 0 10%;
  display: flex;
  align-items: center;
  height: 100%;

}

.nav-items {
  display: flex;
  padding: 0;
  margin-bottom: 0;
  align-items: center;

}

.nav-item {
  display: inline;
  margin-left: 2rem;
}

.nav-item:hover {
  color: black;
  cursor: pointer;
}

/*
 * Home section
 */

#hi-there {
  font-size: 1.75rem;
  margin: 0;
}

#name {
  font-size: 3.25rem;
  font-weight: bold;
  display: block;
  margin-bottom: 5%;
}

/*
 * About section
 */

.tech-stack-list {
  list-style-type: none;
  padding: 0;
  font-size: 1rem;
  font-family: Menlo;
}

.tech-stack-list li {
  padding-bottom: 2.1vh;
}

.tech-stack-list svg {
  margin-right: 3%;
}

@media (max-width: 768px) { 
  .tech-stack-list svg {
    width: 15px!important;
  }
}

/*
 * Project section
 */

 .project-img {
   max-width: 100%;
   max-height: 500px;
   margin: 2vh auto 0 auto;
   display: block;
   
 }

 .project:not(:last-child) {
   margin : 0 0 5vw 0;
 }

 .project .col-sm-5 {
   padding-left: 0;
 }

.project-descr p {
  font-size: 0.9rem;
  color: white;
  padding: 1.5rem;
}

.project-descr {
  background-color: #075F9D;
  border-radius: 1rem;
}

h2.project-title {
  font-size: 1.5rem;
  color: #FFAC50;
}

.project-github .svg-inline--fa.fa-w-16 {
  width: 3rem;
  height: 3rem;
  color: black;
}

.project-github .svg-inline--fa.fa-w-16:hover {
  color: #075F9D;
}

.project-client-img {
  max-width: 100%;
}

.project-client-img {
  padding-bottom: 5vh;
}

/*
 * Contact section
 */


/*
 * Footer 
 */

 .footer {
   min-height: 70px;
 }

 .footer a {
  font-size: 0.9rem;
  font-family: Menlo;
  color: #075F9D;
}

.linkedin-logo .svg-inline--fa.fa-w-14 {
  width: 1.7rem;
  height: 1.7rem;
  margin-top: 1rem;
}

/*
 * General
 */

.text-center {
  text-align: center;
}

@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
    #img-max {
      margin-top: 3rem;
    }
}

@media (max-width: 768px) { 
  .nav .nav-content {
    margin: 0 auto 0 auto;
  }
  .nav .nav-item:first-child {
    margin-left: 0;
  }
}



